export const selectAlert = state => state.alert;

export const selectIsHidden = state => selectAlert(state).isHidden;

export const selectAlertTitle = state => selectAlert(state).title;

export const selectAlertDescription = state => selectAlert(state).description;

export const selectRedirectTo = state => selectAlert(state).redirectTo;

export const selectAlertButtons = state => selectAlert(state).buttons;

export const selectAlertInputs = state => selectAlert(state).inputs;
