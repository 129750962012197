import React, { useEffect, useState } from "react";

import { Button } from "./rounded-button.styles";

const RoundedButton = props => {
	const { children } = props;
	var buttonRef;
	const [borderRadius, setBorderRadius] = useState(10);
	const [fontSize, setFontSize] = useState(16);

	useEffect(() => {
		const buttonHeight = buttonRef.offsetHeight;

		setBorderRadius((buttonHeight / 100) * 25);
		setFontSize(buttonHeight - 8 * 1.5);
	}, [buttonRef]);

	return (
		<Button
			ref={divElement => {
				buttonRef = divElement;
			}}
			borderRadius={borderRadius}
			fontSize={fontSize}
			{...props}
		>
			{children}
		</Button>
	);
};

export default RoundedButton;
