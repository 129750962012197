const subscriptionActionTypes = {
	GET_OFFERS_START: "GET_OFFERS_START",
	GET_OFFERS_FAILURE: "GET_OFFERS_FAILURE",
	GET_OFFERS_SUCCESS: "GET_OFFERS_SUCCESS",
	REDIRECT_TO_CHECKOUT_START: "REDIRECT_TO_CHECKOUT",
	REDIRECT_TO_CHECKOUT_FAILURE: "REDIRECT_TO_CHECKOUT_FAILURE",
	REDIRECT_TO_CHECKOUT_SUCCESS: "REDIRECT_TO_CHECKOUT_SUCCESS",
};

export default subscriptionActionTypes;
