import styled from "styled-components";

export const Container = styled.div`
	width: 300px;
	padding: 10px;
	padding-bottom: 0px;
	min-width: 300px;
`;
export const ButtonContainer = styled.div`
	height: 50px;
	width: 100%;
	display: flex;
	justify-content: center;
	//align-items: center;
	gap: 20px;
`;

export const MovingWhiteContainer = styled.div`
	@media screen and (max-width: 940px) {
		display: unset;
		background-color: #f9f9f9cc;
		backdrop-filter: blur(5px);
		border-radius: 20px;
		padding: 10px;
		z-index: 100;
		position: fixed;
		bottom: 10px;
		left: 20px;
		right: 20px;
		transition-duration: 1s;
		-webkit-box-shadow: 0px 0px 41px -13px rgba(125, 125, 125, 1);
		-moz-box-shadow: 0px 0px 41px -13px rgba(125, 125, 125, 1);
		box-shadow: 0px 0px 41px -13px rgba(125, 125, 125, 1);
		//max-width: 300px;

		//testing the new commit
	}
	display: none;
`;

export const TextButtonContainer = styled.div`
	top: 20px;
	padding-top: 18px;
`;
