import React, { useState } from "react";
import { connect } from "react-redux";

import {
	HeaderContainer,
	Logo,
	BarButtonsContainer,
	BarButtonItem,
	BarButtonIcon,
	SmallText,
	BlackStripe,
	BlackStripesContainer,
	XButtonPartOne,
	XButtonPartTwo,
	XButtonContainer,
	BlankSpacer,
	breakePointForHeader,
} from "./header.styles";
import logo from "../../assets/logo.png";
import settingsIcon from "../../assets/settings.png";
import { Link } from "react-router-dom";

const Header = ({ isLoggedIn }) => {
	const [isMobileScreenSize, setIsMobileScreenSize] = useState(
		window.innerWidth <= breakePointForHeader
	);

	const [areButtonsExpanded, setAreButtonsExpanded] = useState(false);

	window.addEventListener("resize", () => {
		const isMobileNow = window.innerWidth <= breakePointForHeader;
		if (isMobileScreenSize !== isMobileNow) {
			setIsMobileScreenSize(window.innerWidth <= breakePointForHeader);
		}
	});
	window.addEventListener("load", () => {
		setIsMobileScreenSize(window.innerWidth <= breakePointForHeader);
	});

	const HideButtonsOnClick = ({ children }) => (
		<div
			onClick={() => {
				setAreButtonsExpanded(false);
			}}
		>
			{children}
		</div>
	);

	const ExpandButtonsButton = () => (
		<BlackStripesContainer onClick={() => setAreButtonsExpanded(true)}>
			{/* adding a cool new feature(you can delete this) */}
			<BlackStripe />
			<BlackStripe />
			<BlackStripe />
		</BlackStripesContainer>
	);

	const CollapsButtonsButton = () => (
		<XButtonContainer onClick={() => setAreButtonsExpanded(false)}>
			<XButtonPartOne />
			<XButtonPartTwo />
		</XButtonContainer>
	);

	const HeaderButtons = () => (
		<BarButtonsContainer>
			{isMobileScreenSize ? <CollapsButtonsButton /> : null}

			<HideButtonsOnClick>
				<BarButtonItem to="/">HOME</BarButtonItem>
			</HideButtonsOnClick>

			<HideButtonsOnClick>
				<BarButtonItem to="/live-lessons" color={"red"}>
					Live Lessons {"\n"}
					<SmallText>
						MONDAY
						<br /> 7 PM
					</SmallText>
				</BarButtonItem>
			</HideButtonsOnClick>

			{isLoggedIn ? null : (
				<HideButtonsOnClick>
					<BarButtonItem to="/sign-in-up">Sign Up</BarButtonItem>
				</HideButtonsOnClick>
			)}

			<Link to="/settings">
				{isMobileScreenSize ? (
					<HideButtonsOnClick>
						<BarButtonItem to="/settings">SETTINGS</BarButtonItem>
					</HideButtonsOnClick>
				) : (
					<BarButtonIcon src={settingsIcon} alt="settings" />
				)}
			</Link>
		</BarButtonsContainer>
	);

	return (
		<div id="header_div">
			<HeaderContainer>
				<Link to="/">
					<Logo src={logo} alt="logo" />
				</Link>
				{isMobileScreenSize ? (
					areButtonsExpanded ? (
						<HeaderButtons />
					) : (
						<ExpandButtonsButton />
					)
				) : (
					<HeaderButtons />
				)}
			</HeaderContainer>
			<BlankSpacer />
		</div>
	);
};
const mapStateToProps = state => ({
	isLoggedIn: state.currentUser.isLoggedIn,
});

export default connect(mapStateToProps)(Header);
