import styled from "styled-components";

/* possible component properties:
	-withoutUnderline
*/
export const BlackTextButton = styled.div`
	position: relative;
	bottom: 5px;
	text-align: right;
	cursor: pointer;
	transition-duration: 0.2s;
	text-decoration: ${props => {
		if (!props.withoutUnderline) {
			return "underline";
		}
	}};
	&:hover {
		color: gray;
	}
`;
