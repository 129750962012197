import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectResizeListenerTurnOn } from "../redux/metadata/metadata.selector";
import { updateWindowInnerHeightAction } from "../redux/metadata/metadata.actions";

var updateInnerHeightReduxState;

const ReduxMetadataManager = () => {
	const resizeListenerTurnedOn = useSelector(selectResizeListenerTurnOn);
	const dispatch = useDispatch();

	useEffect(() => {
		updateInnerHeightReduxState = () => {
			dispatch(updateWindowInnerHeightAction());
		};
	}, []);

	useEffect(() => {
		if (resizeListenerTurnedOn) {
			updateInnerHeightReduxState();
			window.addEventListener("resize", updateInnerHeightReduxState);
		} else {
			window.removeEventListener("resize", updateInnerHeightReduxState);
		}
	}, [resizeListenerTurnedOn]);

	return <div style={{ display: "none" }}></div>;
};

export default ReduxMetadataManager;
