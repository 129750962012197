import styled from "styled-components";

export const Alert = styled.div`
	background-color: white;
	border-radius: 200px;
	margin: 0 auto;

	@media only screen and (max-width: 720px) {
		margin: 0 5px;
	}
`;

export const Title = styled.p`
	margin: 25px 25px 0 25px;
	font-size: 30px;
`;

export const Description = styled.p`
	color: gray;
	margin: 0 35px;
`;

export const ButtonsContainer = styled.div`
	display: flex;
	height: 40px;
	margin-right: 10px;
	margin-bottom: 5px;
	justify-content: flex-end;
	margin-top: 5px;

	${props => {
		const numberOfButtons = props.numberOfButtons;
		if (numberOfButtons > 1) {
			return `@media only screen and (max-width: 720px) {
			flex-direction: column;
			height: ${numberOfButtons * 35}px;
			margin-top: ${numberOfButtons * 5}px;}`;
		}
	}}
`;

export const ButtonContainer = styled.div`
	@media only screen and (max-width: 720px) {
		height: 35px;
		display: flex;
		justify-content: flex-end;
		margin: 2px 0;
	}
`;

export const InputContainer = styled.div`
	margin: 5px 20px 0px 20px;
`;
