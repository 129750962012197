import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import {
	turnOffResizeListenerAction,
	turnOnResizeListenerAction,
} from "../../redux/metadata/metadata.actions";
import SignInComponent from "../sign-in/sign-in.component";
import SignUpComponent from "../sign-up/sign-up.component";

const SignInUp = ({ history, acknowledgeHeader = false }) => {
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const [showLogIn, setShowLogIn] = useState(
		urlParams.get("startWithLogIn") === "true"
	);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(turnOnResizeListenerAction());

		return () => {
			dispatch(turnOffResizeListenerAction());
		};
	}, []);

	return (
		<div>
			{showLogIn ? (
				<SignInComponent
					opacity={0.85}
					switchToSignUp={() => {
						setShowLogIn(false);
					}}
					{...{ history, acknowledgeHeader }}
				/>
			) : (
				<SignUpComponent
					opacity={0.85}
					switchToLogIn={() => {
						setShowLogIn(true);
					}}
					{...{ history, acknowledgeHeader }}
				/>
			)}
		</div>
	);
};

export default SignInUp;
