import React from "react";

import { BlackTextButton } from "../black-text-button/black-text-button.styles";
import { sendPasswordResetEmail } from "../../firebase/firebase.utils";
import { connect } from "react-redux";
import { showAlertAction } from "../../redux/alert/alert.actions";

const ForgotPasswordButton = ({ showAlert }) => {
	const forgotPasswordClicked = () => {
		showAlert({
			title: "Reset password",
			description: "Where should we send password reset email.",
			buttons: [
				{
					title: "cancel",
					styles: "background-color:gray;margin-right:5px;",
				},
				{
					title: "Send",
					onClick: email => {
						sendPasswordResetEmail(email)
							.then(() => {
								showAlert({ title: "Success" });
							})
							.catch(error => {
								showAlert({
									title: "Oops.",
									description: error.message,
									buttons: [
										{
											title: "cancel",
											styles: "background-color:gray;margin-right:5px;",
										},
										{
											title: "retry",
											onClick: () => {
												forgotPasswordClicked();
											},
										},
									],
								});
							});
					},
					getInputByID: "email",
				},
			],
			inputs: [{ id: "email" }],
		});
	};
	return (
		<BlackTextButton onClick={forgotPasswordClicked}>
			forgot password?
		</BlackTextButton>
	);
};

const mapDispatchToProps = dispatch => ({
	showAlert: alert => dispatch(showAlertAction(alert)),
});

export default connect(null, mapDispatchToProps)(ForgotPasswordButton);
