import React, { useEffect, useState } from "react";

import subscription_bg from "../../assets/subscription_bg.jpg";
import {
	BackgroundImage,
	SubscriptionPageContainer,
	OffersContainer,
	TandCandPPContainer,
} from "./subscriptions-page.styles";
import Offer from "../../components/offer/offer.component";
import { connect } from "react-redux";
import { selectOffers } from "../../redux/subscription/subscription.selectors";
import { getOffersStart } from "../../redux/subscription/subscription.actions";
import {
	getSubscriptionPlatfromAttribute,
	getCurrentSubscriptionProductIDs,
	hasAlreadySubscribedFBFunction,
	getProductIDsofTheStripeSubscriptionAssignedToRCUser,
} from "../../firebase/firebase.utils";
import { selectIsLoggedIn } from "../../redux/current-user/current-user.selector";
import TandC_and_PP from "../../components/t&c-and-pp/t&c-and-pp.component";
import { OfferContainer } from "../../components/offer/offer.styles";
import ShortPromotionVideo from "../../components/short-promotion-video/short-promotion-video.component";
import { addFormilla, removeFormilla } from "../../utils/formilla.utils";

// The subscriptions disappear after user has created an account because the program checks for the "subscrition platform attribute" on Revenuecat before the "on user create" cloud function sets the "subscription platform attribute"
const SubscriptionPage = ({
	offers,
	getSubscriptionOffersStart,
	isLoggedIn,
}) => {
	const [currentSubscriptionProduct, setCurrentSubscriptionProduct] =
		useState([]);
	const [subscriptionPlatform, setSubscriptionPlatform] = useState("none");
	let [hasAlreadySubscribed, setHasAlreadySubscribed] = useState(false);
	let [
		productIDsofTheStripeSubscriptionAssignedToRCUser,
		setproductIDsofTheStripeSubscriptionAssignedToRCUser,
	] = useState("");

	useEffect(() => {
		getSubscriptionOffersStart();
		removeFormilla();
	}, []);

	useEffect(() => {
		//get the subscriber from revenuecat and get the subscriptions with unsubscribe_detected_at=null

		getCurrentSubscriptionProductIDs().then(res => {
			setCurrentSubscriptionProduct(res.data);
		});
		getProductIDsofTheStripeSubscriptionAssignedToRCUser()
			.then(res => {
				setproductIDsofTheStripeSubscriptionAssignedToRCUser(res.data);
			})
			.catch(err => console.error(err));

		if (isLoggedIn) {
			getSubscriptionPlatfromAttribute()
				.then(res => {
					setSubscriptionPlatform(res.data.subscriptionPlatform);
				})
				.catch(error => {
					console.error(
						"this is a get subscription paltfrom attriubute function error"
					);
					console.error(error);
					setSubscriptionPlatform("");
				});

			hasAlreadySubscribedFBFunction().then(res => {
				setHasAlreadySubscribed(res.data.hasAlreadySubscribed);
			});
		}
		const addFormillaIfSreenBigEnough = () => {
			if (window.innerWidth > 940) {
				addFormilla();
			}
		};
		window.addEventListener("load", addFormillaIfSreenBigEnough);

		return () => {
			window.removeEventListener("load", addFormillaIfSreenBigEnough);
		};
	}, [isLoggedIn]);

	return (
		<SubscriptionPageContainer>
			{subscriptionPlatform === "none" ||
			subscriptionPlatform === "stripe" ||
			isLoggedIn === false ? (
				<OffersContainer>
					{/* <ShortPromotionVideo
						currentSubscriptions={currentSubscriptionProduct}
						{...{ hasAlreadySubscribed }}
					/> */}
					{offers.map(offer => (
						<Offer
							key={offer.Title}
							{...offer}
							currentSubscriptions={currentSubscriptionProduct}
							{...{
								productIDsofTheStripeSubscriptionAssignedToRCUser,
							}}
						/>
					))}
					<OfferContainer
						onClick={() => {
							window.open("https://www.learn-polish.pl", "_self");
						}}
					>
						<h3 style={{ margin: "0px" }}>still not sure?</h3>
						<a
							style={{ margin: "0px", color: "blue" }}
							href="https://www.learn-polish.pl"
						>
							learn more
						</a>
					</OfferContainer>
				</OffersContainer>
			) : subscriptionPlatform === "" ? null : (
				<div>
					<h1>
						Mange your subscriptions on your {subscriptionPlatform}{" "}
						device
					</h1>
					<p>-Go to your mobile app</p>
					<p>-Click the Settings icon</p>
				</div>
			)}

			<BackgroundImage
				src={subscription_bg}
				alt="swiss german subscription background"
			/>
		</SubscriptionPageContainer>
	);
};

const mapStateToProps = state => ({
	offers: selectOffers(state),
	isLoggedIn: selectIsLoggedIn(state),
});

const mapDispatchToProps = dispatch => ({
	getSubscriptionOffersStart: () => dispatch(getOffersStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPage);
