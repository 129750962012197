import styled from "styled-components";

import { translucentWhite, opaqueLightGray } from "../../constants/colours";

export const SignInContainer = styled.div`
	/* width: 100%; */
	text-align: center;
	margin-bottom: 500px;
`;

export const StyledLabel = styled.label`
	font-size: 25px;
`;

export const RoundedButtonContainer = styled.div`
	position: relative;
	align-items: center;
	height: 50px;
	margin-top: 10px;
	overflow: hidden;
`;

export const roundedButtonStyles = `
background-color:${translucentWhite};
color:black;
&:hover{
    color:white;
    background-color:#000000bb;
}
display:flex;
gap:5px;
align-items:center;
font-size: min(38px, 8vw);
justify-content:center;
padding-top:8px; 
`;

export const LogoImg = styled.img`
	height: 27px;
	margin: auto 5px auto 0;
`;

export const AppleLogo = styled.img`
	position: absolute;
	left: -10px;
	top: -9px;
	height: 70px;
`;

export const ColorText = styled.text`
	color: ${props => props.color};
	font-size: min(38px, 8vw);
	margin-top: 5px;
`;

export const SmallText = styled.text`
	text-decoration: underline;
	bottom: 0px;
	left: 0px;
	right: 0px;
	position: absolute;
	font-size: 15px;
	color: #4c4cff;
	cursor: pointer;
	font-size: 25px;
	max-width: 160px;
	margin: 0px auto;
`;
