import { all, call, takeLatest, put, select } from "redux-saga/effects";
import LessonsActionTypes from "./lessons.types";
import { firestore } from "../../firebase/firebase.utils";
import "firebase/compat/auth";
import {
	getSecretDataForCurrentLessonSuccess,
	getSecretDataForCurrentLessonFailure,
	getCurrentLessonSuccess,
	getCurrentLessonFailure,
	getCommentFailure,
	getCommentsSuccess,
	sendCommentSuccess,
	sendCommentFailure,
	getCommentsStart,
	deleteCommentSuccess,
	deleteCommentFailure,
	removeCurrentLesson,
	getMondayLessonSuccess,
	getMondayLessonFailure,
} from "./lessons.actions";
import { getSecureData } from "../../firebase/firebase.utils";
import firebase from "firebase/compat/app";
import { selectCurrentUser } from "../current-user/current-user.selector";
import { showAlertAction } from "../alert/alert.actions";

function* getSecretData(action) {
	try {
		var currentLesson = {};
		const DocID = action.payload;

		const secureData = yield getSecureData({ videoID: DocID });
		const Text = secureData.data.Text;
		const VideoHLSurl = secureData.data.VideoHLSurl;
		const Photos = secureData.data.Photos || [];
		const Audios = secureData.data.Audios || [];
		currentLesson = {
			Text: Text,
			VideoHLSurl: VideoHLSurl,
			Photos: Photos,
			Audios: Audios,
		};

		if (secureData.data.hasAccess === true) {
			yield put(getSecretDataForCurrentLessonSuccess(currentLesson));
		} else if (secureData.data.hasAccess === false) {
		} else {
			yield put(getSecretDataForCurrentLessonFailure());
			if (secureData.data.error.code === 1) {
				yield put(
					showAlertAction({
						title: "No access.",
						description:
							"Please Sign in to access this lesson \n \nor make sure the url is correct.",
						redirectTo: "/sign-in-up",
					})
				);
			}
			//set somewhere in redux that the user has not access to BASIC entitlement
		}
	} catch (error) {
		console.error("error in getting secretData: ", error);
		yield put(getSecretDataForCurrentLessonFailure());
	}
}

export function* onGetSecretDataForCurrentLessonStart() {
	yield takeLatest(
		LessonsActionTypes.GET_SECRET_DATA_FOR_CURRENT_LESSON_START,
		getSecretData
	);
}

function* getCurrentLesson(action) {
	try {
		var currentLesson = {};
		const DocID = action.payload;

		const lessonSnap = yield firestore
			.collection("Videos")
			.doc(DocID)
			.get();
		// currentLesson = { ...currentLesson, ...lessonSnap.data() };
		currentLesson = { ...lessonSnap.data() };
		yield put(getCurrentLessonSuccess(currentLesson));
	} catch (error) {
		console.error(error);
		yield put(getCurrentLessonFailure());
	}
}

export function* onGetCurrentLessonStart() {
	yield takeLatest(
		LessonsActionTypes.GET_CURRENT_LESSON_START,
		getCurrentLesson
	);
}

function* getComments(action) {
	const videoID = action.payload;
	var commentsToAdd = [];
	try {
		const commentsSnap = yield firestore
			.collection("Videos")
			.doc(videoID)
			.collection("Comments")
			.orderBy("TimeStamp")
			.limit(100)
			.get();
		commentsSnap.forEach(comment => {
			commentsToAdd = [
				...commentsToAdd,
				{ ...comment.data(), ID: comment.id },
			];
		});
		yield put(getCommentsSuccess(commentsToAdd));
	} catch (error) {
		console.error(error);
		yield put(getCommentFailure());
	}
}

export function* onGetCommentsStart() {
	yield takeLatest(LessonsActionTypes.GET_COMMENTS_START, getComments);
}

function* sendComment(action) {
	const { Message, videoID } = action.payload;
	const { UID, Username } = yield select(selectCurrentUser);
	const comment = {
		Message,
		UID,
		Username,
		TimeStamp: firebase.firestore.Timestamp.now(),
	};

	try {
		yield firestore
			.collection("Videos")
			.doc(videoID)
			.collection("Comments")
			.add(comment);
		yield put(sendCommentSuccess());
		yield put(getCommentsStart(videoID));
	} catch (error) {
		console.error(error);
		yield put(sendCommentFailure());
	}
}

export function* onSendCommentStart() {
	yield takeLatest(LessonsActionTypes.SEND_COMMENT_START, sendComment);
}

function* deleteComment(action) {
	const { id, videoID } = action.payload;

	try {
		yield firestore
			.collection("Videos")
			.doc(videoID)
			.collection("Comments")
			.doc(id)
			.delete();
		yield put(deleteCommentSuccess());
		yield put(getCommentsStart(videoID));
	} catch (error) {
		console.error(error);
		yield put(deleteCommentFailure());
	}
}

export function* onDeleteCommentStart() {
	yield takeLatest(LessonsActionTypes.DELETE_COMMENT_START, deleteComment);
}

function* getMondayLesson(action) {
	const MondayLessonID = action.payload;

	try {
		const MondayLessonSnap = yield firestore
			.collection("Videos")
			.doc(MondayLessonID)
			.get();

		const secureData = yield getSecureData({ videoID: MondayLessonID });
		const Text = secureData.data.Text;
		const VideoHLSurl = secureData.data.VideoHLSurl;
		const Photos = secureData.data.Photos || [];
		const Audios = secureData.data.Audios || [];

		const MondayLesson = {
			Text: Text,
			VideoHLSurl: VideoHLSurl,
			Photos: Photos,
			Audios: Audios,
			...MondayLessonSnap.data(),
		};
		yield put(getMondayLessonSuccess(MondayLesson));
	} catch (error) {
		console.error(error);
		yield put(getMondayLessonFailure());
	}
}

export function* onGetMondayLessonStart() {
	yield takeLatest(
		LessonsActionTypes.GET_MONDAY_LESSON_START,
		getMondayLesson
	);
}

export function* lessonsSagas() {
	yield all([
		call(onGetSecretDataForCurrentLessonStart),
		call(onGetCurrentLessonStart),
		call(onGetCommentsStart),
		call(onSendCommentStart),
		call(onDeleteCommentStart),
		call(onGetMondayLessonStart),
	]);
}
