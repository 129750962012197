import LessonsActionTypes from "./lessons.types";

const INITIAL_STATE = {
	lessons: [],
	currentLesson: {
		Title: "Title",
		Difficulty: 1,
		Text: "Text",
		comments: [],
		MondayLesson: undefined,
		Photos: [],
		Audios: [],
	},
	isLoading: false,
	error: null,
};

const lessonsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case LessonsActionTypes.ADD_LESSONS:
			const LessonsWithoutRepeats = action.payload.map(actionLesson => {
				var lessonToReturn = actionLesson;
				state.lessons.forEach(lesson => {
					if (lesson.DocID === actionLesson.DocID) {
						lessonToReturn = lesson;
					}
				});
				return lessonToReturn;
			});

			return {
				...state,
				lessons: LessonsWithoutRepeats,
			};
		case LessonsActionTypes.MODIFY_LESSONS:
			const StateToAdd = state.lessons.map(stateLesson => {
				var toReturn = stateLesson;
				action.payload.forEach(lesson => {
					if (lesson.DocID === stateLesson.DocID) {
						toReturn = lesson;
					}
				});
				return toReturn;
			});

			return { ...state, lessons: StateToAdd };

		case LessonsActionTypes.SET_CURRENT_LESSON:
		case LessonsActionTypes.GET_CURRENT_LESSON_SUCCESS:
			return {
				...state,
				currentLesson: {
					...state.currentLesson,
					MondayLessonID: undefined,
					isAvailable: undefined,
					isAvailableLessons: undefined,
					GermanVersionURL: undefined,
					...action.payload,
				},
			};
		case LessonsActionTypes.GET_SECRET_DATA_FOR_CURRENT_LESSON_SUCCESS:
			return {
				...state,
				currentLesson: { ...state.currentLesson, ...action.payload },
			};

		case LessonsActionTypes.GET_COMMENTS_SUCCESS:
			const commentsToAdd = action.payload.map(actionComment => {
				var commentToReturn = actionComment;
				state.currentLesson.comments.forEach(stateComment => {
					if (stateComment.ID === actionComment.ID) {
						commentToReturn = stateComment;
					}
				});
				return commentToReturn;
			});

			return {
				...state,
				currentLesson: {
					...state.currentLesson,
					comments: commentsToAdd,
				},
			};
		case LessonsActionTypes.REMOVE_CURRENT_LESSON:
			return {
				...state,
				currentLesson: {
					Title: "Title",
					Difficulty: 1,
					Text: "Text",
					comments: [],
					Photos: [],
					Audios: [],
				},
			};
		case LessonsActionTypes.GET_MONDAY_LESSON_SUCCESS:
			return {
				...state,
				currentLesson: {
					...state.currentLesson,
					MondayLesson: action.payload,
				},
			};
		case LessonsActionTypes.REMOVE_MONDAY_LESSON:
			return {
				...state,
				currentLesson: {
					...state.currentLesson,
					MondayLesson: undefined,
				},
			};
		default:
			return state;
	}
};

export default lessonsReducer;
