import { all, call, takeLatest, put } from "redux-saga/effects";
import liveLessonActionTypes from "./live-lesson.types";
import {
	getLiveLessonUrlsFailure,
	getLiveLessonUrlsSuccess,
} from "./live-lesson.actions";
import {
	startLoading,
	stopLoading,
} from "../loading-animation/loading-animation.actions";
import { getLiveLessonUrls } from "../../firebase/firebase.utils";
import { showAlertAction } from "../alert/alert.actions";

function* getLiveLessonUrlsSaga() {
	try {
		yield put(startLoading());
		const liveLesson = yield getLiveLessonUrls();
		const data = liveLesson.data;
		const liveLessonUrls = {
			liveLessonUrl: data.EmbedUrl,
			chatUrl: data.ChatUrl,
		};
		if (data.hasAccess === true) {
			yield put(getLiveLessonUrlsSuccess(liveLessonUrls));
		} else if (data.hasAccess === false) {
			yield put(
				showAlertAction({
					title: "BASIC or VIP",
					description:
						"Only BASIC or VIP members can access live lessons.",
					redirectTo: "/subscriptions",
				})
			);
		} else {
			yield put(getLiveLessonUrlsFailure());
			if (data.error.code === 1) {
				yield put(
					showAlertAction({
						title: "No access",
						description: "Please Sign in to access live lessons.",
						redirectTo: "/sign-in-up",
					})
				);
			}
		}
		yield put(stopLoading());
	} catch (error) {
		console.error(error);
		yield put(stopLoading());
		yield put(getLiveLessonUrlsFailure());
	}
}

export function* onGetLiveLessonUrlsStart() {
	yield takeLatest(
		liveLessonActionTypes.GET_LIVE_LESSON_URLS_START,
		getLiveLessonUrlsSaga
	);
}

export function* liveLessonSagas() {
	yield all([call(onGetLiveLessonUrlsStart)]);
}
