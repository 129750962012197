import React from "react";

import {
	HorizontalContainer,
	GrayText,
	RedText,
	Container,
} from "./t&c-and-pp.styles";

const TandC_and_PP = ({ Text }) => {
	const TandCClicked = () => {
		window.open("/terms-and-conditions");
	};

	const PPClicked = () => {
		window.open("/privacy-policy");
	};

	return (
		<Container>
			<HorizontalContainer>
				<div>
					<GrayText>
						{Text ? Text : "By logging in"}, you accept Swiss
						German's
					</GrayText>
				</div>
			</HorizontalContainer>
			<HorizontalContainer>
				<RedText onClick={TandCClicked}>terms and conditions</RedText>
				<GrayText>and</GrayText>
				<RedText onClick={PPClicked}>Privacy Policy</RedText>
			</HorizontalContainer>
		</Container>
	);
};

export default TandC_and_PP;
