import React, { useState } from "react";

import {
	Alert,
	Title,
	Description,
	ButtonsContainer,
	InputContainer,
	ButtonContainer,
} from "./custom-alert.styles";
import Background from "../dark-background/dark-background.styles";

import Button from "../rounded-button/rounded-button.component";
import { connect } from "react-redux";
import {
	selectAlertButtons,
	selectAlertDescription,
	selectAlertInputs,
	selectAlertTitle,
	selectIsHidden,
	selectRedirectTo,
} from "../../redux/alert/alert.selectors";
import { hideAlertAction } from "../../redux/alert/alert.actions";
import { useHistory } from "react-router-dom";
import { RoundedInput } from "../sign-in-up-form/rounded-input/rounded-input.styles";

const CustomAlert = ({
	isHidden,
	hideAlert,
	title,
	description,
	redirectTo,
	buttons,
	inputs,
}) => {
	const [inputValues, setInputValues] = useState({});
	const history = useHistory();

	const OkButtonClicked = () => {
		hideAlert();
		if (redirectTo !== "") {
			history.push(redirectTo);
		}
	};

	if (isHidden) {
		return null;
	} else {
		return (
			<Background>
				<Alert>
					<Title>{title}</Title>
					<Description>{description}</Description>
					{inputs.map(input => (
						<InputContainer key={input.id}>
							<RoundedInput
								type={input.id || ""}
								id={input.id}
								value={inputValues[input.id] || ""}
								onChange={event => {
									const id = event.target.id;
									const value = event.target.value;
									setInputValues({
										...inputValues,
										[id]: value,
									});
								}}
							></RoundedInput>
						</InputContainer>
					))}

					<ButtonsContainer numberOfButtons={buttons.length}>
						{buttons.length === 0 ? (
							<Button onClick={OkButtonClicked}>OK</Button>
						) : (
							buttons.map(button => {
								return (
									<ButtonContainer>
										<Button
											key={button.title}
											onClick={() => {
												hideAlert();
												if (button.redirectTo !== "") {
													history.push(
														button.redirectTo
													);
												}
												if (button.onClick)
													button.onClick(
														inputValues[
															button.getInputByID
														] || null
													);
											}}
											styles={button.styles}
										>
											{button.title}
										</Button>
									</ButtonContainer>
								);
							})
						)}
					</ButtonsContainer>
				</Alert>
			</Background>
		);
	}
};

const mapStateToProps = state => ({
	isHidden: selectIsHidden(state),
	title: selectAlertTitle(state),
	description: selectAlertDescription(state),
	redirectTo: selectRedirectTo(state),
	buttons: selectAlertButtons(state),
	inputs: selectAlertInputs(state),
});

const mapDispatchToProps = dispatch => ({
	hideAlert: () => dispatch(hideAlertAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomAlert);
