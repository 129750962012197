import { all, call, takeLatest, put, select } from "redux-saga/effects";

import { firestore, getSecureData } from "../../firebase/firebase.utils";
import { showAlertAction } from "../alert/alert.actions";
import {
	chooseRandomMiniLessonActionFailure,
	chooseRandomMiniLessonActionSuccess,
	getMiniLessonFailure,
	getMiniLessonStart,
	getMiniLessonSuccess,
	getMiniLessonsIDsIndexActionFailure,
	getMiniLessonsIDsIndexActionSuccess,
	getNewsetMiniLessonFailure,
	getNewsetMiniLessonSuccess,
} from "./mini-lessons.actions";
import miniLessonsActionTypes from "./mini-lessons.types";

import { selectMiniLessons } from "./mini-lesson.selectors";
import {
	startLoading,
	stopLoading,
} from "../loading-animation/loading-animation.actions";

function* getMiniLessonsIDsIndex() {
	try {
		const snap = yield firestore
			.collection("Videos")
			.doc("mini_lessons_collection_attributes")
			.get();
		yield put(getMiniLessonsIDsIndexActionSuccess(snap.data().index));
	} catch (error) {
		console.error(error);
		yield put(
			showAlertAction({
				title: "Oops.",
				description: "Something went wrong. Try again",
			})
		);
		yield put(getMiniLessonsIDsIndexActionFailure());
	}
}

function* getNewsetMiniLesson() {
	try {
		console.log("started ");
		const snap = yield firestore
			.collection("Videos")
			.where("Type", "==", "mini_lesson")
			.orderBy("Date", "desc")
			.limit(1)
			.get();
		var data = {};
		snap.forEach(doc => {
			data = doc.data();
		});
		yield put(getNewsetMiniLessonSuccess(data));
	} catch (err) {
		console.error(err);
		yield put(getNewsetMiniLessonFailure());
	}
}

function* getMiniLesson(action) {
	try {
		yield put(startLoading());

		const LessonID = action.payload;

		const res = yield getSecureData({ videoID: LessonID });

		if (res.data.VideoHLSurl && res.data.Text) {
			yield put(getMiniLessonSuccess({ ...res.data, ID: LessonID }));
			yield put(stopLoading());
		} else {
			yield put(
				showAlertAction({
					title: "VIP/BASIC only",
					description:
						"Take our 30 day free trial and test our VIP course",
					redirectTo: "/subscriptions",
				})
			);
			//display alert about the problem. fetching data not successfull
		}
	} catch (err) {
		console.error(err);
		yield put(getMiniLessonFailure());
	}
}

function* chooseRandomMiniLesson() {
	try {
		yield put(startLoading());
		var { currentIndexElementNumber, IDsIndexRandomized } = yield select(
			selectMiniLessons
		);

		currentIndexElementNumber =
			(currentIndexElementNumber + 1) % IDsIndexRandomized.length;
		if (IDsIndexRandomized) {
			yield put(
				getMiniLessonStart(
					IDsIndexRandomized[currentIndexElementNumber]
				)
			);
			yield put(
				chooseRandomMiniLessonActionSuccess(currentIndexElementNumber)
			);
		} else {
			throw "could not choose a random mini lesson because Index of randomized mini lesson ids does not exist";
		}
	} catch (err) {
		console.error(err);
		yield put(chooseRandomMiniLessonActionFailure());
	}
}

export function* onGetNewestMiniLessonStart() {
	yield takeLatest(
		miniLessonsActionTypes.GET_NEWEST_MINI_LESSON_START,
		getNewsetMiniLesson
	);
}

export function* onGetMiniLessonsIDsIndexStart() {
	yield takeLatest(
		miniLessonsActionTypes.GET_MINI_LESSONS_IDS_INDEX_START,
		getMiniLessonsIDsIndex
	);
}

export function* onGetMiniLessonStart() {
	yield takeLatest(
		miniLessonsActionTypes.GET_MINI_LESSON_START,
		getMiniLesson
	);
}

export function* onChooseRandomMiniLessonStart() {
	yield takeLatest(
		miniLessonsActionTypes.CHOOSE_RANDOM_MINI_LESSON_START,
		chooseRandomMiniLesson
	);
}

export function* miniLessonsSagas() {
	yield all([
		call(onGetMiniLessonsIDsIndexStart),
		call(onGetNewestMiniLessonStart),
		call(onGetMiniLessonStart),
		call(onChooseRandomMiniLessonStart),
	]);
}
