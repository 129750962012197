export const offers = [
	{
		Title: "VIP",
		PromotionText: [
			"Includes BASIC plan",
			"live lessons every Monday at 8 PM",
			"Live text chat during Monday 8 PM lesson",
			"Speaking practise after every lesson on Zoom",
			"access to recordings from all previous lessons",
			"previous name:'Monday 8 PM' course",
		],
		ButtonText: "Subscribe",
		VideoHLSurl:
			"https://player.vimeo.com/external/769430853.m3u8?s=f7f6f4553dc03fc78585553b809d132fe49f5cb5",
	},
	{
		Title: "BASIC",
		PromotionText: [
			"new (2-5 minute)video every week",
			"Swiss German to English translations",
			"flashcards to every video",
			"access to all previous videos (60+)",
		],
		ButtonText: "Subscribe",
	},
];
