import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
	OfferContainer,
	ButtonContainer,
	OfferTitle,
	Divider,
	Price,
	Benefits,
	BenefitsTitle,
	Information,
} from "./offer.styles";
import RoundedButton from "../rounded-button/rounded-button.component";

import { selectIsLoggedIn } from "../../redux/current-user/current-user.selector";
import { redirectToCheckoutStart } from "../../redux/subscription/subscription.actions";
import {
	changeStripeSubscription,
	hasAlreadySubscribedFBFunction,
	getCurrentSubscriptionProductIDs,
	createStripeCardUpdateCheckoutSession,
	uncancelStripeSubscription,
} from "../../firebase/firebase.utils";
import {
	startLoading,
	stopLoading,
} from "../../redux/loading-animation/loading-animation.actions";
import { showAlertAction } from "../../redux/alert/alert.actions";
import { stripePromise } from "../../stripe/stripe.utils";
import { stripeProductsIDs } from "../../stripe/stripe.utils";
import LoadingAnimationComponent from "../loading-animation/loading-animation.component";
import TandC_and_PP from "../t&c-and-pp/t&c-and-pp.component";

const Offer = ({
	Title,
	ButtonText,
	PromotionText,
	StripePrice,
	StripePriceID,
	isLoggedIn,
	redirectToCheckout,
	currentSubscriptions,
	startLoadingAnimation,
	stopLoadingAnimation,
	showAlert,
	ProductID,
	productIDsofTheStripeSubscriptionAssignedToRCUser,
}) => {
	let [hasAlreadySubscribed, setHasAlreadySubscribed] = useState(false);
	let [nextRenewOrCancelText, setNextRenewOrCancelText] = useState("");

	const isCurrentSubscription = Object.keys(currentSubscriptions).some(
		id => id === ProductID
	);

	const [willCancel, setWillCancel] = useState(false);

	var currentSubscription = "none";

	Object.keys(currentSubscriptions).forEach(id => {
		if (currentSubscriptions[id].cancels === false) {
			if (id === stripeProductsIDs.BASIC) {
				currentSubscription = "BASIC";
			} else if (id === stripeProductsIDs.VIP) {
				currentSubscription = "VIP";
			}
		}
	});
	if (
		currentSubscription === "none" &&
		Object.keys(currentSubscriptions).length > 0
	) {
		currentSubscription = "all_canceled";
	}

	useEffect(() => {
		if (isLoggedIn) {
			hasAlreadySubscribedFBFunction().then(res => {
				setHasAlreadySubscribed(res.data.hasAlreadySubscribed);
			});
		}
	}, []);
	useEffect(() => {
		if (isCurrentSubscription === true) {
			//get current subscription that cancels and update cancelText

			getCurrentSubscriptionProductIDs().then(res => {
				const subscriptions = res.data;
				Object.keys(subscriptions).forEach(id => {
					if (
						id === ProductID &&
						subscriptions[id].cancels === true
					) {
						var t = new Date(subscriptions[id].expires_date);
						setNextRenewOrCancelText(
							"canceles on " + t.toDateString()
						);
						setWillCancel(true);
					} else if (
						id === ProductID &&
						subscriptions[id].cancels === false
					) {
						var t = new Date(subscriptions[id].expires_date);
						setNextRenewOrCancelText(
							"renews on " + t.toDateString()
						);
					}
				});
			});
		}
	}, [isCurrentSubscription]);

	const SubscribeWasClicked = async () => {
		if (currentSubscription === "none") {
			redirectToCheckout({
				StripePriceID,
				subscriptionEntitlementName: Title,
			});
		} else if (
			currentSubscription === "VIP" ||
			currentSubscription === "BASIC" ||
			currentSubscription === "all_canceled"
		) {
			startLoadingAnimation();
			await changeStripeSubscription({
				StripePriceID,
				ProductID,
				subscriptionEntitlementName: Title,
			})
				.then(async res => {
					const session = res.data;
					const stripe = await stripePromise;
					stripe.redirectToCheckout({
						sessionId: session.id,
					});
				})
				.catch(error => {
					stopLoadingAnimation();
					showAlert({
						title: "Something went wrong.",
						description: "try again",
					});
				});

			//change subsciption item
		}
	};
	const UpdateCardDetailsWasClicked = async () => {
		startLoadingAnimation();
		createStripeCardUpdateCheckoutSession()
			.then(async res => {
				const session = res.data;
				const stripe = await stripePromise;
				stripe.redirectToCheckout({
					sessionId: session.id,
				});
			})
			.catch(error => {
				stopLoadingAnimation();
				showAlert({
					title: "Something went wrong.",
					description: "try again",
				});
			});
	};

	function SubscribeButtonText() {
		if (isCurrentSubscription && !willCancel) return "current";
		else if (
			isCurrentSubscription &&
			willCancel &&
			productIDsofTheStripeSubscriptionAssignedToRCUser[0] !== ProductID
		)
			return "";
		else if (
			isCurrentSubscription &&
			willCancel &&
			productIDsofTheStripeSubscriptionAssignedToRCUser[0] === ProductID
		)
			return "resubscribe";
		else if (
			currentSubscription === "none" ||
			currentSubscription === "all_cancels"
		) {
			if (hasAlreadySubscribed) {
				return ButtonText;
			} else {
				return "TRY OUT";
			}
		} else if (currentSubscription === "VIP") {
			return "downgrade";
		} else return "upgrade";
	}

	return (
		<OfferContainer>
			<OfferTitle id={ProductID + "_title"}>{Title}</OfferTitle>

			<LoadingAnimationComponent>
				{!isCurrentSubscription ? (
					<div>
						<BenefitsTitle>What you get:</BenefitsTitle>
						{PromotionText.map(Text => (
							<div key={Text}>
								<Benefits>{Text}</Benefits>
								<Divider />
							</div>
						))}
					</div>
				) : null}

				<ButtonContainer>
					<Information>{nextRenewOrCancelText}</Information>
					{isCurrentSubscription &&
					willCancel &&
					productIDsofTheStripeSubscriptionAssignedToRCUser[0] !==
						ProductID ? null : (
						<RoundedButton
							onClick={() => {
								if (!isCurrentSubscription) {
									SubscribeWasClicked();
								} else if (
									isCurrentSubscription &&
									willCancel &&
									productIDsofTheStripeSubscriptionAssignedToRCUser[0] ===
										ProductID
								) {
									startLoadingAnimation();
									uncancelStripeSubscription({
										productIDToUncancel: ProductID,
									}).then(res => {
										stopLoadingAnimation();
										window.location.reload();
									});
								}
							}}
							FullWidth
							styles={
								isCurrentSubscription
									? "background-color:black"
									: ""
							}
							id={ProductID + "_subscribe_button"}
						>
							<SubscribeButtonText />
						</RoundedButton>
					)}
				</ButtonContainer>

				{isCurrentSubscription && !willCancel ? (
					<ButtonContainer styles={"padding-top: 10px;"}>
						<RoundedButton
							FullWidth
							styles={"background-color:gray"}
							onClick={UpdateCardDetailsWasClicked}
						>
							update card info
						</RoundedButton>
					</ButtonContainer>
				) : null}

				<Price>
					<b>{hasAlreadySubscribed ? "" : "1 month free"}</b>
					{hasAlreadySubscribed ? "" : ", then "}
					{StripePrice ? StripePrice.currency : "=="}{" "}
					{StripePrice ? StripePrice.unit_amount / 100 : "=="} per
					month
				</Price>
				{!isCurrentSubscription ? (
					<TandC_and_PP Text={"By subscribing"} />
				) : null}
			</LoadingAnimationComponent>
		</OfferContainer>
	);
};

const mapStateToProps = state => ({
	isLoggedIn: selectIsLoggedIn(state),
});

const mapDispatchToProps = dispatch => ({
	redirectToCheckout: payload => dispatch(redirectToCheckoutStart(payload)),
	startLoadingAnimation: () => dispatch(startLoading()),
	stopLoadingAnimation: () => dispatch(stopLoading()),
	showAlert: alert => dispatch(showAlertAction(alert)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Offer);
