import React, { useState, useEffect } from "react";

import { HlsVideoPlayer, RegularVideoPlayer } from "./video.styles";

const VideoPlayer = ({
	srcVideo,
	autoPlay,
	aspectRatio = [1920, 1080],
	controls = true,
	transitionDuration = 1,
	muted = false,
	loop = false,
	style = {},
	id,
	isHLSType = true,
}) => {
	const [videoHeight, setVideoHeight] = useState(100);
	//the Kafele video does not work on this video player

	const resizeVideoPlayer = () => {
		const videoPlayerWidth = document.getElementById(
			`HlsVideoPlayer:${srcVideo}`
		).offsetWidth;
		setVideoHeight((videoPlayerWidth * aspectRatio[1]) / aspectRatio[0]);
	};

	useEffect(() => {
		window.removeEventListener("load", resizeVideoPlayer);
		window.removeEventListener("resize", resizeVideoPlayer);

		window.addEventListener("resize", resizeVideoPlayer);
		window.addEventListener("load", resizeVideoPlayer);

		resizeVideoPlayer();
		return () => {
			window.removeEventListener("load", resizeVideoPlayer);
			window.removeEventListener("resize", resizeVideoPlayer);
		};
	}, [srcVideo]);

	if (isHLSType) {
		return (
			<HlsVideoPlayer
				id={"HlsVideoPlayer:" + srcVideo}
				className={"HlsVideoPlayer"}
				src={srcVideo}
				autoPlay={autoPlay}
				controls={controls}
				height={`${videoHeight}px`}
				transitionduration={transitionDuration}
				muted={muted}
				loop={loop}
				playsInline
				style={{ ...style }}
			></HlsVideoPlayer>
		);
	} else {
		return (
			<RegularVideoPlayer
				id={"HlsVideoPlayer:" + srcVideo}
				className={"HlsVideoPlayer"}
				src={srcVideo}
				autoPlay={autoPlay}
				controls={controls}
				height={`${videoHeight}px`}
				transitionduration={transitionDuration}
				muted={muted}
				loop={loop}
				playsInline
				style={{ ...style }}
			></RegularVideoPlayer>
		);
	}
};

export default VideoPlayer;
