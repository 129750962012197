import firebase from "firebase/compat/app";

import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/analytics";
import "firebase/compat/functions";
import "firebase/compat/app-check";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

const config = {
	apiKey: "AIzaSyDQ1htKcotmNYClKlO2-qPtcjdbgip3OoY",
	authDomain: "polish-1afa6.firebaseapp.com",
	projectId: "polish-1afa6",
	storageBucket: "polish-1afa6.appspot.com",
	messagingSenderId: "787703878649",
	appId: "1:787703878649:web:d7f9879cd86c0fcd44a328",
	measurementId: "G-SVYGYKJYFQ",
};

firebase.initializeApp(config);
firebase.analytics();

export const convertLessonsSnapshotToMap = snapshot => {
	const transformedLessons = snapshot.docs.map(doc => {
		return doc.data();
	});

	return transformedLessons;
};

var functions = firebase.functions();

var googleProvider = new firebase.auth.GoogleAuthProvider();

var appleProvider = new firebase.auth.OAuthProvider("apple.com");

const appCheck = firebase.appCheck();

export const signInWithGoogle = () =>
	firebase.auth().signInWithPopup(googleProvider);

export const signInWithApple = () =>
	firebase.auth().signInWithPopup(appleProvider);

export const firebaseAuth = () => firebase.auth();

export const firestore = firebase.firestore();

if (process.env.NODE_ENV === "development") {
	// functions.useEmulator("localhost", 5001);
	// connectFirestoreEmulator(firestore, "localhost", 8080);
	// firebaseAuth().useEmulator("http://localhost:9099");
	// firestore.useEmulator("localhost", 8080);
	window.FIREBASE_APPCHECK_DEBUG_TOKEN =
		process.env.REACT_APP_APP_CHECK_DEBUG_KEY;
}

appCheck.activate(
	new firebase.appCheck.ReCaptchaEnterpriseProvider(
		"6Ler4AgoAAAAANC5xDsppsbxt1NFqBiTZLMW4YIZ"
	),
	true // Set to true to allow auto-refresh.
);

export const getSecureData = functions.httpsCallable("getSecureData");

export const hasBASICEntitlement = functions.httpsCallable(
	"hasBASICEntitlement"
);

export const hasVIPEntitlement = functions.httpsCallable("hasVIPEntitlement");

export const getLiveLessonUrls = functions.httpsCallable("getLiveLessonUrls");

export const createStripeCheckoutSession = functions.httpsCallable(
	"createStripeCheckoutSession"
);

export const getRevenuCatOffer = functions.httpsCallable("getRevenuCatOffer");

export const getUserEntitlements = functions.httpsCallable(
	"getUserEntitlements"
);

export const changeStripeSubscription = functions.httpsCallable(
	"changeStripeSubscription"
);

export const cancelStripeSubscription = functions.httpsCallable(
	"cancelStripeSubscription"
);

export const getSubscriptionPlatfromAttribute = functions.httpsCallable(
	"getSubscriptionPlatfromAttribute"
);

export const CreateZoomSignature = functions.httpsCallable(
	"CreateZoomSignature"
);

export const updateUsersProfile = data => {
	const user = firebase.auth().currentUser;

	return user.updateProfile(data);
};

const updateRCandACuserEmail = functions.httpsCallable(
	"updateRCandACuserEmail"
);

export const updateUsersEmail = async email => {
	const user = firebase.auth().currentUser;

	await user.updateEmail(email).catch(err => {
		console.error(err);
		throw err;
	});
	return updateRCandACuserEmail({ email });
};

export const sendPasswordResetEmail = email => {
	return firebase.auth().sendPasswordResetEmail(email);
};

export const hasAlreadySubscribedFBFunction = functions.httpsCallable(
	"checkIfHasAlreadySubscribed"
);

export const CheckIfUserCanceled = functions.httpsCallable(
	"CheckIfUserCanceled"
);

/**
 * get all not expired subscriptions. Format: {sub_id:{cancels:true,expires_date:date}}
 */
export const getCurrentSubscriptionProductIDs = functions.httpsCallable(
	"getCurrentSubscriptionProductIDs"
);

export const createStripeCardUpdateCheckoutSession = functions.httpsCallable(
	"createStripeCardUpdateCheckoutSession"
);

export const getFlashcardsCloudFunction = functions.httpsCallable(
	"getFlashcardsCloudFunction"
);

export const getGrammarPDFurl = functions.httpsCallable("getGrammarPDFurl");

export const requestAccountDeletion = functions.httpsCallable(
	"requestAccountDeletion"
);

export const uncancelStripeSubscription = functions.httpsCallable(
	"uncancelStripeSubscription"
);

export const getProductIDsofTheStripeSubscriptionAssignedToRCUser =
	functions.httpsCallable(
		"getProductIDsofTheStripeSubscriptionAssignedToRCUser"
	);
