import React, { useEffect, lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import { connect } from "react-redux";

import Header from "./components/header/header.component";
import LoadingPage from "./pages/loading-page/loading.page";

import { firebaseAuth } from "./firebase/firebase.utils";
import { addUser, removeUser } from "./redux/current-user/current-user.actions";
import SubscriptionPage from "./pages/subscriptions-page/subscriptions.page";
import CustomAlert from "./components/custom-alert/custom-alert.component";

import SignUpOverlay from "./components/sign-in-up-overlay/sign-in-up-overlay.component";
import {
	getMiniLessonsIDsIndexActionStart,
	getNewsetMiniLessonStart,
} from "./redux/mini-lessons/mini-lessons.actions";
import {
	fetchConsentsFromFirestoreStartAction,
	resetConsentsAction,
} from "./redux/consents/consents.actions";
import ReduxMetadataManager from "./utils/redux-metadata-manager.component";

const SuccessCardUpdatePage = lazy(() =>
	import("./pages/success-card-update-page/success-card-update.page")
);
const SignInOutPage = lazy(() =>
	import("./pages/sign-in-up-page/sign-in-up.page")
);
const LiveLessonPage = lazy(() =>
	import("./pages/live-lesson-page/live-lesson.page")
);
const SuccessPage = lazy(() => import("./pages/success-page/success.page"));
const MyAccountPage = lazy(() =>
	import("./pages/my-account-page/my-account.page")
);
const CancelPage = lazy(() => import("./pages/cancel-page/cancel.page"));
const PageNotFound = lazy(() => import("./pages/404-page/404.page"));
const LiveMeetingPage = lazy(() =>
	import("./pages/live-meeting-page/live-meeting.page.jsx")
);
const ContactPage = lazy(() => import("./pages/contact-page/contact.page"));
const FlashcardsPage = lazy(() =>
	import("./pages/flashcards-page/flashcards.page")
);
const VideoPagesContainerPage = lazy(() =>
	import(
		"./pages/video-pages-container-page/video-pages-container-page.container"
	)
);
const MiniLessonsPage = lazy(() =>
	import("./pages/mini-lessons-page/mini-lessons.page")
);
const SettingsPage = lazy(() => import("./pages/settings-page/settings.page"));
// const GrammarPage = lazy(() => import("./pages/grammar-page/grammar.page"));
const CollectConsentsPage = lazy(() =>
	import("./pages/collect-consents-page/collect-consents.page")
);
const PrivacyPolicyPage = lazy(() =>
	import("./pages/privacy-policy/privacy-policy.page")
);
const TermsAndConditions = lazy(() =>
	import("./pages/terms-and-conditions/terms-and-conditions.page")
);

const Disclaimer = lazy(() => import("./pages/disclaimer/disclaimer.page"));

function App({
	addUser,
	removeUser,
	getMiniLessonsIDsIndex,
	resetConsents,
	fetchConsentsFromFirestoreStart,
	getNewestMiniLesson,
}) {
	useEffect(() => {
		firebaseAuth().onAuthStateChanged(user => {
			if (user) {
				addUser({
					UID: user.uid,
					Username: user.displayName,
					email: user.email,
				});
				fetchConsentsFromFirestoreStart();
			} else {
				removeUser();
				resetConsents();
			}
		});
		getMiniLessonsIDsIndex();
		getNewestMiniLesson();
	}, []);

	return (
		<div className="App" id="App">
			<SignUpOverlay />
			<Header />
			<Suspense fallback={<LoadingPage />}>
				<Switch>
					<Route
						exact
						path={["/", `/videos/:VideoID`, "/home"]}
						component={VideoPagesContainerPage}
					/>
					<Route
						exact
						path={"/sign-in-up"}
						component={SignInOutPage}
					/>
					<Route
						exact
						path={"/live-lessons"}
						component={LiveLessonPage}
					/>
					<Route exact path={"/settings"} component={SettingsPage} />
					<Route
						exact
						path={"/subscriptions"}
						component={SubscriptionPage}
					/>
					<Route
						exact
						path={"/success/:session_id?"}
						component={SuccessPage}
					/>
					<Route
						exact
						path={"/my-account"}
						component={MyAccountPage}
					/>
					<Route exact path={"/cancel"} component={CancelPage} />
					<Route exact path={"/contact"} component={ContactPage} />
					<Route
						exact
						path={"/live-meeting"}
						component={LiveMeetingPage}
					/>
					<Route
						exact
						path={"/success-card-update"}
						component={SuccessCardUpdatePage}
					/>
					<Route
						exact
						path={"/flashcards/:VideoID"}
						component={FlashcardsPage}
					/>
					{/* <Route
						exact
						path={"/grammar-page"}
						component={GrammarPage}
					/> */}
					<Route
						exact
						path={"/mini-lessons/:LessonID"}
						component={MiniLessonsPage}
					/>
					<Route
						exact
						path="/collect-consents"
						component={CollectConsentsPage}
					/>
					<Route
						path="/privacy-policy"
						component={PrivacyPolicyPage}
					/>
					<Route
						path="/terms-and-conditions"
						component={TermsAndConditions}
					/>
					<Route path="/disclaimer" component={Disclaimer} />
					<Route>
						<PageNotFound />
					</Route>
				</Switch>
			</Suspense>
			<CustomAlert />
			<ReduxMetadataManager />
		</div>
	);
}

const mapDispatchToProps = dispatch => ({
	addUser: user => dispatch(addUser(user)),
	removeUser: () => dispatch(removeUser()),
	getMiniLessonsIDsIndex: () => dispatch(getMiniLessonsIDsIndexActionStart()),
	resetConsents: () => dispatch(resetConsentsAction()),
	fetchConsentsFromFirestoreStart: () =>
		dispatch(fetchConsentsFromFirestoreStartAction()),
	getNewestMiniLesson: () => dispatch(getNewsetMiniLessonStart()),
});

export default connect(null, mapDispatchToProps)(App);
