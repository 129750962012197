import styled from "styled-components";

import { opaqueLightGray, almostBlack } from "../../constants/colours";

export const OfferContainer = styled.div`
	display: flex;
	background-color: ${opaqueLightGray};
	backdrop-filter: blur(3px);
	border-radius: 40px;
	align-items: center;
	flex-direction: column;
	padding: 20px;
	margin: 0 10px;
	transition-duration: 0.2s;

	@media only screen and (min-width: 940px) {
		&:hover {
			padding: 30px;
			margin: 0 0;
		}
	}
	@media only screen and (max-width: 940px) {
		margin: 5px;
	}
`;

export const ButtonContainer = styled.div`
	height: 50px;
	width: 100%;
	padding-top: 40px;
	overflow: hidden;
	position: relative;
	${props => props.styles}
	min-width: 200px;
`;

export const Information = styled.p`
	content: "blah";
	position: absolute;
	top: 20px;
	left: 2px;
	margin: 0px;
	overflow: unset;
`;

export const OfferTitle = styled.p`
	font-size: 70px;
	color: red;
	margin: 0;

	@media only screen and (max-width: 940px) {
		font-size: 60px;
	}
`;

export const Divider = styled.div`
	background-color: gray;
	height: 1px;
	width: 100%;
`;

export const Price = styled.p`
	margin: 2px;
	color: ${almostBlack};
`;

export const Benefits = styled.p`
	margin: 5px;
	color: gray;
`;

export const BenefitsTitle = styled.p`
	font-size: 20px;
	margin: 5px;
`;
