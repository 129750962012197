import { all, takeLatest, call, select, put } from "redux-saga/effects";
import consentsActionTypes from "./consents.types";
import { setDoc, doc, serverTimestamp, getDoc } from "firebase/firestore";
import { firestore } from "../../firebase/firebase.utils";
import { selectConsents } from "./consents.selector";
import {
	fetchConsentsFromFirestoreFailureAction,
	fetchConsentsFromFirestoreSuccessAction,
	updateConsentsAction,
	uploadConsentsToFirestoreFailureAction,
	uploadConsentsToFirestoreSuccessAction,
} from "./consents.actions";
import { selectUID } from "../current-user/current-user.selector";

function sleep(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

function* fetchConsentsFromFirestore(action) {
	yield sleep(2000);
	const uid = yield select(selectUID);
	try {
		const userDoc = yield getDoc(doc(firestore, `Users/${uid}`));
		if (
			userDoc.exists() &&
			userDoc.data() &&
			userDoc.data().PrivacyPolicyAndTermsAndConditionsConsent &&
			userDoc.data().PrivacyPolicyAndTermsAndConditionsConsent
				.didUserAgree
		) {
			const {
				PrivacyPolicyAndTermsAndConditionsConsent,
				MarketingConsent,
				RemindersConsent,
			} = userDoc.data();
			yield put(
				updateConsentsAction({
					PPandTandCConsent:
						PrivacyPolicyAndTermsAndConditionsConsent.didUserAgree,
					RemindersConsent: RemindersConsent.didUserAgree,
					MarketingConsent: MarketingConsent.didUserAgree,
				})
			);
			yield put(fetchConsentsFromFirestoreSuccessAction());
		} else {
			//display consents
			//get consents from user
			if (
				window.location.href.includes("/collect-consents") === false &&
				window.location.href.includes("/terms-and-conditions") ===
					false &&
				window.location.href.includes("/privacy-policy") === false
			) {
				window.location.href = "/collect-consents";
			}
		}
	} catch (err) {
		console.error(err);
		yield put(fetchConsentsFromFirestoreFailureAction());
	}
}

function* uploadConsentsToFirestore(action) {
	var uid;
	if (action.payload.getUidFromRedux) {
		uid = yield select(selectUID);
	} else {
		uid = action.payload.uid;
	}

	const { PPandTandCConsent, RemindersConsent, MarketingConsent } =
		yield select(selectConsents);
	try {
		yield setDoc(
			doc(firestore, `Users/${uid}`),
			{
				DocID: uid,
				PrivacyPolicyAndTermsAndConditionsConsent: {
					didUserAgree: PPandTandCConsent,
					Date: new serverTimestamp(),
				},
				RemindersConsent: {
					didUserAgree: RemindersConsent,
					Date: new serverTimestamp(),
				},
				MarketingConsent: {
					didUserAgree: MarketingConsent,
					Date: new serverTimestamp(),
				},
			},
			{ merge: true }
		);
		console.log("success");
		yield put(uploadConsentsToFirestoreSuccessAction());
		if (action.payload.completionHandler)
			yield action.payload.completionHandler();
	} catch (err) {
		console.error(err);
		yield put(uploadConsentsToFirestoreFailureAction());
	}
}

export function* onUploadConsentsToFirestoreStart() {
	yield takeLatest(
		consentsActionTypes.UPLOAD_CONSENTS_TO_FIRESTORE_START,
		uploadConsentsToFirestore
	);
}

export function* onFetchConsentsFromFirestore_and_OnUploadConsentsToFirestoreSuccess() {
	yield takeLatest(
		[
			consentsActionTypes.FETCH_CONSENTS_FROM_FIRESTORE_START,
			consentsActionTypes.UPLOAD_CONSENTS_TO_FIRESTORE_SUCCESS,
		],
		fetchConsentsFromFirestore
	);
}

export function* consentsSagas() {
	yield all([
		call(onUploadConsentsToFirestoreStart),
		call(
			onFetchConsentsFromFirestore_and_OnUploadConsentsToFirestoreSuccess
		),
	]);
}
