import AlertActionTypes from "./alert.types";

const INITIAL_STATE = {
	isHidden: true,
	title: "example error",
	description: "actually there is no error",
	redirectTo: "",
	buttons: [],
};

const alertReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case AlertActionTypes.SHOW_ALERT:
			return {
				...state,
				title: "",
				description: "",
				redirectTo: "",
				buttons: [],
				isHidden: false,
				inputs: [],
				...action.payload,
			};
		case AlertActionTypes.HIDE_ALERT:
			return { ...state, isHidden: true };
		default:
			return state;
	}
};

export default alertReducer;
