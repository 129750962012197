import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { redirectToCheckoutStart } from "../../redux/subscription/subscription.actions";
import { BlackTextButton } from "../black-text-button/black-text-button.styles";
import RoundedButton from "../rounded-button/rounded-button.component";
import TandC_and_PP from "../t&c-and-pp/t&c-and-pp.component";
import VideoPlayer from "../video/video.component";
import { StripePriceID } from "../../constants/subscriptionIDs";
import { stripeProductsIDs } from "../../stripe/stripe.utils";

import {
	ButtonContainer,
	Container,
	MovingWhiteContainer,
	TextButtonContainer,
} from "./short-promotion-video.styles";

const ShortPromotionVideo = ({ redirectToCheckout, hasAlreadySubscribed }) => {
	useEffect(() => {
		const clientScrolled = () => {
			const VIP_subscribe_button = document.getElementById(
				`${stripeProductsIDs.VIP}_subscribe_button`
			);

			const try_out_VIP_button =
				document.getElementById("try_out_vip_button");

			if (VIP_subscribe_button && try_out_VIP_button) {
				const VIP_subscribe_button_rect =
					VIP_subscribe_button.getBoundingClientRect();

				const try_out_VIP_button_rect =
					try_out_VIP_button.getBoundingClientRect();

				if (
					VIP_subscribe_button_rect.top <
					try_out_VIP_button_rect.top + 90
				) {
					//setIsVisible(false);
					document.getElementById(
						"MovingWhiteContainer"
					).style.opacity = "0";
				} else {
					document.getElementById(
						"MovingWhiteContainer"
					).style.opacity = "1";
				}
			}
		};
		window.addEventListener("scroll", clientScrolled);
		return () => {
			window.removeEventListener("scroll", clientScrolled);
		};
	}, []);

	function ConversionButtonClicked() {
		redirectToCheckout({
			StripePriceID: StripePriceID.VIP,
			subscriptionEntitlementName: "VIP",
		});
	}

	return (
		<Container>
			<VideoPlayer
				//16: 9 video format
				srcVideo={
					"https://player.vimeo.com/external/807934746.m3u8?s=147e27c315af42bc305f1157cac81a12cb72febc"
				}
				autoPlay={true}
				aspectRatio={[9, 16]}
				controls={false}
				transitionDuration={0}
				muted={true}
				loop={true}
			></VideoPlayer>

			<MovingWhiteContainer id={"MovingWhiteContainer"}>
				{!hasAlreadySubscribed ? (
					<div>
						<ButtonContainer>
							<RoundedButton
								id={"try_out_vip_button"}
								onClick={ConversionButtonClicked}
							>
								Try out VIP
							</RoundedButton>
							<TextButtonContainer>
								<BlackTextButton
									onClick={() => {
										window.scrollTo(
											0,
											document
												.getElementById(
													`${stripeProductsIDs.BASIC}_title`
												)
												.getBoundingClientRect().top -
												100
										);
									}}
								>
									{" "}
									view BASIC{" "}
								</BlackTextButton>
							</TextButtonContainer>
						</ButtonContainer>
						<TandC_and_PP Text={"By subscribing"} />
					</div>
				) : (
					"↓"
				)}
			</MovingWhiteContainer>
		</Container>
	);
};

const mapDispatchToProps = dispatch => ({
	redirectToCheckout: payload => {
		dispatch(redirectToCheckoutStart(payload));
	},
});

export default connect(null, mapDispatchToProps)(ShortPromotionVideo);
