import styled from "styled-components";
import { opaqueLightGray } from "../../constants/colours";

export const SubscriptionPageContainer = styled.div`
	position: relative;
	width: 100%;
	min-height: calc(100vh - 70px);
	display: flex;

	/* @media only screen and (max-width: 720px) {
		height: unset;
	} */
`;

export const BackgroundImage = styled.img`
	position: absolute;

	bottom: 0px;

	right: 0px;
	height: 80%;
	z-index: 0;

	@media only screen and (max-width: 940px) {
		height: 250px;
	}
`;

export const OffersContainer = styled.div`
	position: absolute;
	display: flex;
	flex-direction: row;
	top: 50px;
	left: 50px;
	z-index: 800;
	align-items: flex-start;

	@media only screen and (max-width: 940px) {
		position: unset;
		flex-direction: column;
		margin-bottom: 250px;
		align-items: center;
		width: 100%;
	}
`;

export const TandCandPPContainer = styled.div`
	position: absolute;
	bottom: 5px;
	left: 10px;
	background-color: #ffffffaa;
`;
