import subscriptionActionTypes from "./subscription.types";

export const getOffersStart = () => ({
	type: subscriptionActionTypes.GET_OFFERS_START,
});

export const getOffersFailure = () => ({
	type: subscriptionActionTypes.GET_OFFERS_FAILURE,
});

export const getOffersSuccess = offers => ({
	type: subscriptionActionTypes.GET_OFFERS_SUCCESS,
	payload: offers,
});

export const redirectToCheckoutStart = payload => ({
	type: subscriptionActionTypes.REDIRECT_TO_CHECKOUT_START,
	payload: payload,
});

export const redirectToCheckoutSuccess = () => ({
	type: subscriptionActionTypes.REDIRECT_TO_CHECKOUT_SUCCESS,
});

export const redirectToCheckoutFailure = () => ({
	type: subscriptionActionTypes.REDIRECT_TO_CHECKOUT_FAILURE,
});
