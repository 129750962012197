import { loadStripe } from "@stripe/stripe-js";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

export const useTestPayments = false;

var stripePublicKey;
var ProductsIDs = {};

if (process.env.NODE_ENV === "development" && useTestPayments) {
	stripePublicKey =
		"pk_test_51NpRYcE263KsJdwOegYAyi8kEoLfdMu5rd1uW4a3hS8jDmLVVPzD7E5q6xq8tM5eVehXGDgOQ4FMsoC6soKCAEJs00vt5SeKRH";
	ProductsIDs = {
		VIP: "prod_OchGMeXGtCm19T",
		BASIC: "prod_OchA6UQqb3upqK",
	};
} else {
	stripePublicKey =
		"pk_live_51NpRYcE263KsJdwOmcAfya7mlL60NMJKuQ0xjH04waSAibYIZkuFO8kMuMId0hfws1TMsl8T368xRyjuHfAXSwIC0003c3RH3q";
	ProductsIDs = {
		VIP: "prod_OdqkIKW6P4h6eH",
		BASIC: "prod_Odqql9uOwMUSDC",
	};
}

export const stripePromise = loadStripe(stripePublicKey);

export const stripeProductsIDs = ProductsIDs;
