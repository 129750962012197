import { offers } from "./subscription.data";
import subscriptionActionTypes from "./subscription.types";
import { stripeProductsIDs } from "../../stripe/stripe.utils";

const INITIAL_STATE = { offers: offers };

const subscriptionReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case subscriptionActionTypes.GET_OFFERS_SUCCESS:
			var offers_to_return = [];
			action.payload.forEach(offer => {
				var to_return = {};
				if (offer.ProductID === stripeProductsIDs.VIP) {
					to_return = { ...state.offers[0] };
				}
				offers_to_return.push({ ...to_return, ...offer });
			});
			return { ...state, offers: offers_to_return };
		default:
			return state;
	}
};

export default subscriptionReducer;
