import miniLessonsActionTypes from "./mini-lessons.types";
import miniLessonsTypes from "./mini-lessons.types";

const INITIAL_STATE = {
	IDsIndex: [],
	current: { ID: "", VideoThumbnailURL: "", VideoHLSurl: "", Text: "" },
	newestMiniLesson: { DocID: "", VideoThumbnailURL: "" },
	IDsIndexRandomized: [],
	currentIndexElementNumber: 0,
};

const miniLessonsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case miniLessonsTypes.GET_MINI_LESSONS_IDS_INDEX_SUCCESS:
			var IDsIndexRandomized;
			IDsIndexRandomized = state.IDsIndex.sort(
				(a, b) => 0.5 - Math.random()
			);
			return { ...state, IDsIndex: action.payload, IDsIndexRandomized };
		case miniLessonsActionTypes.CHOOSE_RANDOM_MINI_LESSON_SUCCESS:
			return { ...state, currentIndexElementNumber: action.payload };
		case miniLessonsActionTypes.GET_NEWEST_MINI_LESSON_SUCCESS:
			if (action.payload.DocID) {
				return {
					...state,
					newestMiniLesson: { ...action.payload },
				};
			}
			return { ...state, current: action.payload };
		case miniLessonsActionTypes.GET_MINI_LESSON_SUCCESS:
			return { ...state, current: action.payload };
		default:
			return state;
	}
};

export default miniLessonsReducer;
