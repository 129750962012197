import { all, call, takeLatest, put, select } from "redux-saga/effects";
import {
	createStripeCheckoutSession,
	getRevenuCatOffer,
} from "../../firebase/firebase.utils";
import {
	getOffersFailure,
	getOffersSuccess,
	redirectToCheckoutFailure,
	redirectToCheckoutSuccess,
} from "./subscription.actions";
import subscriptionActionTypes from "./subscription.types";
import {
	startLoading,
	stopLoading,
} from "../loading-animation/loading-animation.actions";
import { selectIsLoggedIn } from "../current-user/current-user.selector";
import { stripePromise } from "../../stripe/stripe.utils";
import { showAlertAction } from "../alert/alert.actions";
import { startSignUpFlow } from "../current-user/current-user.actions";
import { useTestPayments } from "../../stripe/stripe.utils";

function* getOffers() {
	try {
		yield put(startLoading());
		var offerID;
		if (process.env.NODE_ENV === "development" && useTestPayments) {
			offerID = "test_offer";
		} else {
			offerID = "BASIC_VIP_monthly";
		}
		const products = yield getRevenuCatOffer({
			offerID,
		});
		yield put(getOffersSuccess(products.data));
		yield put(stopLoading());
	} catch (error) {
		yield put(getOffersFailure());
		console.error(error);
	}
}

function* onGetOffersStart() {
	yield takeLatest(subscriptionActionTypes.GET_OFFERS_START, getOffers);
}

function* redirectToCheckout(action) {
	const { StripePriceID, subscriptionEntitlementName } = action.payload;
	const isLoggedIn = yield select(selectIsLoggedIn);
	if (isLoggedIn) {
		try {
			yield put(startLoading());
			const stripe = yield stripePromise;
			const response = yield createStripeCheckoutSession({
				priceId: StripePriceID,
				subscriptionEntitlementName: subscriptionEntitlementName,
			});
			const session = yield response.data;
			const res = yield stripe.redirectToCheckout({
				sessionId: session.id,
			});
			//this isn't executed beacause the user is redirected to stripe checkout

			yield put(redirectToCheckoutSuccess());
		} catch (error) {
			yield put(stopLoading());
			yield put(
				showAlertAction({
					title: "Oops.",
					description: "Something went wrong.",
				})
			);
			yield put(redirectToCheckoutFailure());
			console.error(error);
		}
	} else {
		yield put(redirectToCheckoutFailure());
		yield put(
			startSignUpFlow([
				{
					action: subscriptionActionTypes.REDIRECT_TO_CHECKOUT_START,
					payload: action.payload,
				},
			])
		);
	}
}

function* onRedirectToCheckoutStart() {
	yield takeLatest(
		subscriptionActionTypes.REDIRECT_TO_CHECKOUT_START,
		redirectToCheckout
	);
}

export function* subscriptionSagas() {
	yield all([call(onGetOffersStart), call(onRedirectToCheckoutStart)]);
}
