import React from "react";
import { connect } from "react-redux";
import "./loading-animation.styles.css";
import { selectIsLoading } from "../../redux/loading-animation/loading-animation.selectors";

const LoadingAnimation = ({ isLoading, children }) => {
	if (isLoading) {
		return (
			<div className='loading-animation-container'>
				<div className='lds-ellipsis'>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		);
	} else {
		return children ? children : <div></div>;
	}
};

const mapStateToProps = state => ({
	isLoading: selectIsLoading(state),
});

export default connect(mapStateToProps)(LoadingAnimation);
